<template>
  <v-row>
    <v-col
      cols="12"
      class="text-right">
      <v-tooltip
        v-if="isSoldOut"
        top>
        <template v-slot:activator="{ on, attrs }">
          <img
            v-bind="attrs"
            width="28"
            class="sold-out-img"
            src="@/assets/image/out-of-stock.png"
            alt="out-of-stock"
            v-on="on">
        </template>
        <span>ของในหน้าเว็บหมดจ้าา</span>
      </v-tooltip>
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            v-if="bcSync && bcSync.sync && bcSync.bcLog"
            class="mr-4"
            v-bind="attrs"
            color="success"
            v-on="on">
            mdi-check-circle-outline
          </v-icon>
          <v-icon
            v-else
            class="mr-4"
            v-bind="attrs"
            color="error"
            v-on="on">
            mdi-sync-alert
          </v-icon>
        </template>
        <span v-if="bcSync && bcSync.updatedAt">
          Last sync date {{ bcSync.updatedAt | dateTimeUserFormat() }}
        </span>
        <span v-else>
          Not sync yet
        </span>
      </v-tooltip>
      <v-btn
        depressed
        small
        color="primary"
        :to="{
          name: 'EditProduct',
          params: {
            productId: product.id
          }
        }"
        link>
        <span class="body-2 ml-1">
          Edit Product
        </span>
      </v-btn>
    </v-col>
    <v-col
      cols="12"
      sm="2"
      md="2"
      lg="2"
      xl="1">
      <gw-product-image
        :src="product.photoUrls[0]"
        class="cursor-pointer"
        @click="previewImg(product.photoUrls[0])" />
    </v-col>
    <v-col
      cols="12"
      sm="10"
      md="10"
      lg="10"
      xl="11"
      class="pa-4">
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          xl="4">
          <p class="mb-0">
            ชื่อสินค้า : <strong class="font-weight-bold">{{ product.name }}</strong>
          </p>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          xl="4">
          <p class="mb-0">
            รุ่นสินค้า: {{ product.model }}
          </p>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          xl="4">
          <p class="mb-0">
            รหัสนามแฝง: {{ aliasId }}
          </p>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          xl="4">
          <p class="mb-0">
            คอลเลกชัน: {{ collection }}
          </p>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          xl="4">
          <p class="mb-0">
            หมวดหมู่สินค้า (Website):
            {{ product.categories.length ? mapCategories(product.categories) : 'ไม่ระบุ' }}
          </p>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          xl="4">
          <p class="mb-0">
            หมวดหมู่สินค้า (Report): {{ product.productPrototype ? product.productPrototype.category : 'ไม่ระบุ' }}
          </p>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          xl="4">
          <p
            v-if="groupCategories.length"
            class="mb-0">
            กลุ่มหมวดหมู่สินค้า:
            <v-chip
              v-for="(gc, i) in groupCategories"
              :key="`${product.id}-gc-${gc}-${i}`"
              class="ma-1"
              text-color="white"
              color="info"
              small>
              {{ gc }}
            </v-chip>
          </p>
          <p
            v-else
            class="mb-0">
            กลุ่มหมวดหมู่สินค้า: ไม่ระบุ
          </p>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          xl="4">
          <p class="ma-0">
            แบรนด์: {{ product.brand }}
          </p>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
        >
          <p class="ma-0">
            แท็ก:
            <v-chip
              v-for="(tag, i) in product.tags"
              :key="`${product.id}-tag-${tag}-${i}`"
              class="ma-1"
              text-color="white"
              color="#c379aa"
              small>
              {{ tag.name || tag }}
            </v-chip>
          </p>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      v-if="!isMobile"
      cols="12"
      class="pa-4">
      <v-simple-table class="stock-table pa-3 pt-0">
        <thead>
          <tr>
            <th
              class="sticky-column"
              style="left: 0px;">
              <tr>
                <th
                  v-for="(haedSku, i) in skusDetails"
                  :key="`skusDetails-${i}`"
                  class="text-center"
                  :style="`min-width: ${haedSku.dWidth}px;`">
                  {{ haedSku.dLabel }}
                </th>
              </tr>
            </th>
            <th
              v-for="(warehouse, i) in warehouses"
              :key="`warehouse-${i}`"
              width="150"
              class="text-center">
              {{ warehouse.name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(sku, i) in product.skus"
            :key="`skus-${i}`">
            <td
              style="left: 0px;"
              class="sticky-column">
              <tr>
                <td style="min-width: 170px;">
                  {{ sku.code }}
                </td>
                <td
                  style="min-width: 80px;"
                  class="text-center">
                  {{ sku.size.name }}
                </td>
                <td
                  style="min-width: 80px;"
                  class="text-center">
                  {{ sku.color.name }}
                </td>
                <td
                  style="min-width: 80px;"
                  class="text-center">
                  {{ sku.price }}
                </td>
              </tr>
            </td>
            <td
              v-for="(warehouse, j) in sku.warehouses"
              :key="`warehouse-skus-${j}`"
              width="65"
              class="text-center">
              <span
                :style="{
                  'color': isRemaining(warehouse) ? '#01ED01' : '#000000',
                  'font-weight': isRemaining(warehouse) ? 'bold' : 'normal'
                }">
                {{ `${warehouse.onHandQty} (${warehouse.onReservedQty})` }}
              </span>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-col>
    <v-col
      v-else
      cols="12"
      class="pa-4">
      <v-simple-table class="stock-table pa-3 pt-0">
        <thead>
          <tr>
            <th
              v-for="(haedSku, i) in skusDetails"
              :key="`skusDetails-${i}`"
              class="text-center"
              :class="haedSku.stick ? 'sticky-column' : ''"
              :style="`min-width: ${haedSku.mWidth}px; ${haedSku.stick ? 'left: 0' : ''}`">
              {{ haedSku.mLabel }}
            </th>
            <th
              v-for="(warehouse, i) in warehouses"
              :key="`warehouse-${i}`"
              width="150"
              class="text-center">
              {{ warehouse.name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(sku, i) in product.skus"
            :key="`skus-${i}`">
            <td
              style="left: 0px;"
              class="sticky-column text-center">
              {{ sku.size.name }}
            </td>
            <td style="min-width: 170px;">
              {{ sku.code }}
            </td>
            <td
              style="min-width: 80px;"
              class="text-center">
              {{ sku.color.name }}
            </td>
            <td
              style="min-width: 80px;"
              class="text-center">
              {{ sku.price }}
            </td>
            <td
              v-for="(warehouse, j) in sku.warehouses"
              :key="`warehouse-skus-${j}`"
              width="65"
              class="text-center">
              <span
                :style="{
                  'color': isRemaining(warehouse) ? '#01ED01' : '#000000',
                  'font-weight': isRemaining(warehouse) ? 'bold' : 'normal'
                }">
                {{ `${warehouse.onHandQty} (${warehouse.onReservedQty})` }}
              </span>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import SizeValues from '@/assets/js/SizeValues'

export default {
  props: {
    aliasId: {
      type: Number,
      required: true
    },
    productId: {
      type: String,
      default: ''
    },
    isSoldOut: {
      type: Boolean,
      default: false
    },
    productName: {
      type: String,
      default: ''
    },
    gwCollection: {
      type: Object,
      default: () => {}
    },
    productPrototype: {
      type: Object,
      default: null
    },
    groupCategories: {
      type: Array,
      default: () => []
    },
    model: {
      type: String,
      default: ''
    },
    brand: {
      type: String,
      default: ''
    },
    skus: {
      type: Array,
      default: () => []
    },
    categories: {
      type: Array,
      default: () => []
    },
    tags: {
      type: Array,
      default: () => []
    },
    photoUrls: {
      type: Array,
      default: () => []
    },
    warehouses: {
      type: Array,
      default: () => []
    },
    bcSync: {
      type: Object,
      default: () => {}
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      previewEnabled: false,
      currentImgPreview: null,
      skusDetails: [
        { mLabel: 'size', dLabel: 'code', mWidth: '80', dWidth: '170', stick: true },
        { mLabel: 'code', dLabel: 'size', mWidth: '170', dWidth: '80', stick: false },
        { mLabel: 'color', dLabel: 'color', mWidth: '80', dWidth: '80', stick: false },
        { mLabel: 'price', dLabel: 'price', mWidth: '80', dWidth: '80', stick: false }
      ]
    }
  },
  computed: {
    collection () {
      let result = ''
      if (this.gwCollection && this.gwCollection.nickname) {
        result += this.gwCollection.nickname
      } else {
        result += '-'
      }
      if (this.gwCollection && this.gwCollection.name) {
        result += ` ( ${this.gwCollection.name} )`
      }

      return result
    },
    product () {
      return {
        id: this.productId,
        name: this.productName,
        model: this.model,
        brand: this.brand,
        skus: this.skus,
        categories: this.categories,
        productPrototype: this.productPrototype,
        tags: this.tags,
        gwCollection: this.gwCollection,
        photoUrls: this.photoUrls
      }
    }
  },
  mounted () {
    this.sortSize()
  },
  methods: {
    previewImg (img) {
      this.$emit('on-preview-image', img)
    },
    mapCategories (data) {
      return data.map((r) => r).join(', ')
    },
    getLastNumber (num = 0) {
      const number = num.toString()
      return number[number.length - 1]
    },
    sortSize () {
      this.product.skus = this.product.skus.sort((a, b) => SizeValues(a.size.name) - SizeValues(b.size.name))
      this.product.skus = this.product.skus.map((sku) => {
        const warehouses = this.warehouses.map((warehouse) => this.getQtySkuByWarehouse(sku, warehouse))

        return {
          ...sku,
          warehouses
        }
      })
    },
    getQtySkuByWarehouse (sku, warehouse) {
      const stock = sku.stock.find((r) => r.warehouse.id === warehouse.id)
      const onHandQty = stock && stock.onHandQty ? stock.onHandQty : 0
      const onReservedQty = stock && stock.onReservedQty ? stock.onReservedQty : 0

      return {
        ...warehouse,
        onHandQty,
        onReservedQty
      }
    },
    isRemaining (warehouse) {
      return warehouse.onHandQty > 0 && warehouse.id !== 0
    }
  }
}
</script>

<style scoped>
.sold-out-img {
  position: absolute;
}
img.img-lzd {
  display: block;
}
.skus-table {
  border: 1px solid  #858585;
}
.skus-table th {
  text-transform: capitalize;
}
.stock-table {
  border: 1px solid  #858585;
  margin: -1px;
}
h4.product-name {
  margin-bottom: .5rem;
}
.mock-image {
  position: relative;
  padding-top: 133.333%;
}
.mock-image-svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cursor-pointer {
  cursor: pointer;
}
.sticky-column {
  position: sticky !important;
  background: white;
  z-index: 2;
  border-right: 1px solid #ddd;
}
thead .sticky-column {
  top: 0;
  z-index: 3;
  border-bottom: 1px solid #ddd;
}
</style>
